import * as React from "react"
import Layout from '../components/layout'
import { FormLogin } from "../components/login/FormLogin"
import logo from "../images/logo-dark.png"


// markup
const Login = () => {
  return (
    <Layout>
      <div className="container-xl">
        <div className="row mt-5 justify-content-center">
          <div className="col-1">
            <img src={logo} alt="colegio" width="100"/>
          </div>
        </div>
        <div className="row mt-3 justify-content-center">
          <div className="col-6">
            <FormLogin />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Login
